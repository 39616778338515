<template>
    <div class="lineManagement">
        <jy-query ref="checkForm" :model="formInline">
            <jy-query-item label="定点专线名称:" prop="name" label-width="100px">
                <el-input v-model="formInline.name" placeholder="请输入"></el-input>
            </jy-query-item>
            <jy-query-item label="所属线路:" prop="routeId">
                <!-- <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="formInline.routeName"></el-input>
                </div> -->
                <el-select v-model="formInline.routeId" placeholder="请选择线路">
                    <el-option v-for="item in routeOptions" :key="item.routeId" :label="item.name" :value="item.routeId">
                    </el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck" v-if="btnexist('list')">查询</el-button>
                <el-button type="primary" @click="resetForm('checkForm')" v-if="btnexist('reset')">重置</el-button>
                <el-row>
                    <el-button type="primary" @click="add" v-if="btnexist('insert')">新增</el-button>
                    <el-button type="primary" @click="batchdelete" v-if="btnexist('batchDelete')">批量删除</el-button>
                    <!-- <el-button type="primary" @click="batchimport">导出</el-button> -->
                    <!-- <el-button type="primary" @click="batchimport">导入</el-button> -->
                </el-row>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="lists" @select-all="selectallchange" @select="selectchange">
            <jy-table-column type="index" width="60" label="序号"> </jy-table-column>
            <jy-table-column type="selection" width="40"> </jy-table-column>
            <jy-table-column prop="routeName" label="所属线路" min-width="100"> </jy-table-column>
            <jy-table-column prop="orgName" label="所属机构" min-width="180"> </jy-table-column>
            <jy-table-column prop="name" label="专线名称" min-width="100"> </jy-table-column>
            <jy-table-column prop="upMileage" label="上行里程(km)" min-width="120"> </jy-table-column>
            <jy-table-column prop="downMileage" label="下行里程(km)" min-width="120"> </jy-table-column>
            <jy-table-column prop="totalMileage" label="总里程（km）" min-width="120"> </jy-table-column>
            <jy-table-column prop="task" label="专线任务" min-width="100"> </jy-table-column>
            <jy-table-column prop="remark" label="备注" min-width="200"> </jy-table-column>
            <jy-table-column prop="createTime" label="创建日期" min-width="160"> </jy-table-column>
            <jy-operate v-bind:list="operateList" width="160"></jy-operate>
        </jy-table>
        <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total">
        </jy-pagination>
        <add-dialog ref="dialog" @updateList="getlist"></add-dialog>
        <institutions-tree ref="institutions" @addInstitutions="addInstitutions" url="/waybillOperate/getTree" title="选择所属机构" :defaultProps="props"></institutions-tree>
    </div>
</template>
<script>
import adddialog from '@/components/pages/admin/basicInformation/fixedLineManagement/addfixedLine'
import institutionsTree from '@/components/pages/admin/common/dialog-tree/institutions_tree'
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            formInline: {
                name: '',
                routeName: '',
                routeId: '',
            },
            props: {
                children: 'children',
                label: 'text',
                value: 'id',
            },
            lists: [
                {
                    routeName: '',
                    name: '',
                    upMileage: '',
                    downMileage: '',
                    totalMileage: '',
                    task: '',
                    remark: '',
                    createTime: '',
                },
            ],
            // 批量删除
            batchdeleteList: [],
            // 分页
            pageIndex: 1,
            total: 100,
            pageSize: 10,
            //  操作
            operateList: [],

            routeOptions:[],

            btnRoute:'/admin/basicInformation/fixedLineManagement',
        }
    },
    mixins:[btnMixins],
    components: {
        'add-dialog': adddialog,
        institutionsTree,
    },
    created() {
        this.getlist()
        this.setOperateList()
    },
    activated(){
        this.getRouteList()
    },
    methods: {
        getRouteList(){
            let url = '/baseinforoute/queryCurrentAuthorityRoute'
            this.$http.post(url).then(({detail})=>{
                this.routeOptions = detail
            })
        },
        setOperateList() {
            let l = [
                {
                    name: '修改',
                    icon: 'el-icon-edit-outline',
                    fun: this.handleEdit,
                    isShow:()=>{
                        return this.btnexist('update')
                    },
                },
                {
                    name: '删除',
                    icon: 'el-icon-delete',
                    fun: this.handleDelete,
                    isShow:()=>{
                        return this.btnexist('delete')
                    },
                },
            ]
            this.operateList = l
        },
        getlist() {
            let url = '/baserentroute/list'
            let option = {
                ...this.formInline,
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            this.$http.post(url, option).then(({ detail }) => {
                this.lists = detail.list
                this.total = detail.total
            })
        },
        deleteRecord(brandIdAry) {
            this.$http.post('/baserentroute/delete', brandIdAry).then(res => {
                this.$message({
                    message: res.msg,
                    type: 'success',
                })
                this.getlist()
            })
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getlist()
            console.log('更新')
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
            this.formInline.routeId = ''
            this.oncheck()
        },
        // 导出
        onexport() {
            console.log('导出')
        },
        // 新增
        add() {
            this.$refs.dialog.init()
            console.log('新增')
        },
        // 所属线路
        addInstitutions(data) {
            // console.log(data)
            this.formInline.routeId = data.id
            this.formInline.routeName = data.text
        },
        // 组织线路
        selectInstitutions() {
            this.$refs.institutions.init()
        },
        // 批量删除
        batchdelete() {
            this.$confirm('是否删除该文件?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let option = []
                    this.batchdeleteList.forEach(item => {
                        option.push(item.rentRouteId)
                    })
                    this.deleteRecord(option)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
        },
        // 批量导入
        batchimport() {},

        selectchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },
        selectallchange(selection) {
            console.log(selection)
            this.batchdeleteList = selection
        },

        // 删除
        handleDelete(row) {
            this.$confirm('是否删除该文件?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let params = []
                    params.push(row.rentRouteId)
                    this.deleteRecord(params)
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除',
                    })
                })
            //   console.log(row)
        },
        // 修改
        handleEdit(row) {
            this.$refs.dialog.init(row, 'update')
            console.log('编辑')
        },
        // 分页
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`)
            this.pageSize = val
            this.getlist()
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.pageIndex = val
            this.getlist()
        },
    },
}
</script>
<style lang="scss" scoped="brand">
</style>
<style lang="scss">
</style>
